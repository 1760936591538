import { createContext, createEffect, Show, Suspense, useContext } from 'solid-js';
import { useAnalytics } from '@troon/analytics';
import { setUser } from '@sentry/solidstart';
import { getRequestEvent, isServer } from 'solid-js/web';
import { cache, createAsync, redirect } from '@solidjs/router';
import { loggedInUserQuery } from '../queries/user';
import { cachedQuery } from '../graphql/cached-get';
import type { Accessor, ParentProps } from 'solid-js';
import type { LoggedInUserQuery } from '../graphql/__generated__/graphql';

type Data = LoggedInUserQuery;
type CTX = Accessor<Data | null>;

const User = createContext<CTX>();

export function UserContext(props: ParentProps) {
	const user = createAsync(getLoggedInUser, { deferStream: true });
	const analytics = useAnalytics();

	createEffect(() => {
		const data = user();
		if (data) {
			analytics?.identify(data.me.id, { email: data.me.email, fullName: `${data.me.firstName} ${data.me.lastName}` });
			if (!isServer && window.__USERID__ && window.__USERID__ !== data.me.id) {
				analytics?.alias(window.__USERID__);
			}
			setUser({ id: data.me.id, email: data.me.email, fullName: `${data.me.firstName} ${data.me.lastName}` });
		}
	});

	return (
		<Suspense>
			<Show when={user() !== undefined}>
				<User.Provider value={user as Accessor<Data | null>}>{props.children}</User.Provider>
			</Show>
		</Suspense>
	);
}

export function useUser(): Accessor<LoggedInUserQuery | null> {
	const ctx = useContext(User);
	if (!ctx) {
		throw new Error('User context not initialized');
	}
	return ctx;
}

const internalQuery = cachedQuery(loggedInUserQuery, { onError: () => {} });
const getLoggedInUser = async () => {
	const res = await internalQuery({});
	return res || null;
};

export const requireLoggedIn = cache(async () => {
	const user = await getLoggedInUser();
	if (user === null) {
		const url = isServer ? new URL(getRequestEvent()!.request.url) : new URL(window.location.toString());
		if (!url.searchParams.get('redirect')) {
			url.searchParams.set('redirect', url.pathname);
		}
		return redirect(`/auth?${url.searchParams.toString()}`, { status: 401 });
	}
	return user;
}, 'require-logged-in');
